window.HowlerConfig = {
  algolia: {
    applicationId: 'K4FPULVWDS',
    apiKey: 'cdec92c6ac1ad99de33520350ef41eee'
  },
  google: {
    apiKey: 'AIzaSyA08DP3--CmYlb2AYmNeLUlE3t_frEYJYk'
  },
  breakpoints: {
    small: 0,
    medium: 720,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1440
  },
  environment: 'production'
};
