import { performOnVisaCheckoutReady } from './visa-checkout-script';

export default function visaCheckoutInit(total, currency) {
  performOnVisaCheckoutReady(function() {
    V.init({
      apikey: "NRV82DDRDPPKZF5UH2W821osXxIDr7qtH9Fen3di-aLcZZKjM",
      paymentRequest: {
        currencyCode: currency,
        total: total
      }
    });
  });
}