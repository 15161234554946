
  document.addEventListener('turbolinks:load', function () {

    function getMeta(name) {
      var metaTag = document.querySelector(`meta[name="${name}"]`);
      if (metaTag) {
        return metaTag.content;
      }
      return '';
    }

    if (window.fcWidget) {
      window.fcWidget.init({
        token: "e8c9218d-37b5-4b66-a413-e4a5813c0451" ,
        host: "https://wchat.freshchat.com",
        externalId: getMeta("howler:user.email"),
        firstName: getMeta("howler:user.firstName"),
        lastName: getMeta("howler:user.lastName"),
        email: getMeta("howler:user.email"),
        phone: getMeta("howler:user.phone")
      });
    }

  });

