import Turbolinks from 'turbolinks';

(() => {
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');

  
  fbq('init', '151090252152813');
  fbq('init', '204043020402329');

  

  function trackPageView() {
    fbq('track', 'PageView');
  }

  if (Turbolinks && Turbolinks.supported) {
    document.addEventListener('turbolinks:load', function(event) {
      trackPageView();
    }, true);
  }
})();
